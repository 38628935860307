.bind-hn-page{
  margin-top: 2%;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  display: block;

  .ui.form .fields .field {

    label{
      font-size: 18px;
    }

    .ui.button,
    .ui.button:focus,
    .ui.button:hover {
      background-color: var(--primary-theme-color);
      color: var(--primary-font-color);
    } 
  }

  .ReactTable{
    height: 80vh;
  }
}