:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --std-border: 0;
}

.ChatTable.noMargin {
  margin: 0;
}

.ui.celled.table.ChatTable.noMargin {
  margin: 0;
}

.ChatTable td.chatBox {
  padding: 0;
}

.ChatTable .chatbox-header .fields {
  justify-content: flex-end;
  background-color: var(--secondary-bg-color);
  margin: 0;
}

.ChatTable .chatbox-header .fields .field .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.ChatTable .chatbox-header .fields .field .ui.button.inverted-btn {
  color: var(--primary-theme-color);
  border: 2px solid var(--primary-theme-color, black);
  background-color: transparent;
}

.ChatTable .PatientPanel .main-segment .btn-download {
  background: #0072bc;
  padding: 16px 45px;
  color: white;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.ui.modal.classify {
  width: 500px;
}

.ChatTable td.chatBox .ChatBox {
  /* height: calc(100vh - 40px); */
}

.ChatTable td.chatList {
  padding: 0;
  vertical-align: top;
}

.ChatTable td.chatDetail {
  padding: 0;
  vertical-align: top;
}

.ChatTable td.chatDetail .ChatDetail {
  /* height: 93vh; */
}

.ChatTable td.chatDetail .PatientPanel {
  /* height: 93vh; */
  width: 100%;
}

.ChatTable td.chatList .ChatList,
.ChatTable td.chatList .ChatList .Dimmer {
  height: calc(100vh - 40px);
}

.ChatTable td.chatList {
  vertical-align: middle;
  /* height: 100%; */
  /* height: 93vh; */
}

.ChatTable .chatBox .chat-loader {
  position: relative;
  padding: 20px;
}

.ChatTable .chatDetail .ChatDetail {
  /* height: 93vh; */
}

.ChatTable .chatDetail iframe {
  height: calc(100vh - 40px);
  width: 100%;
}

.ChatTable .chatDetail .PatientPanel,
.ChatTable .chatDetail .PatientPanel .dimmable {
  /* height: 93vh; */
  height: calc(100vh - 40px);
  width: 100%;
}

.ChatTable .chatList .ChatList,
.ChatTable .chatList .ChatList .Dimmer {
  height: calc(100vh - 90px);
}

.ChatTable .chatList .chatListError {
  /* height: 93vh; */
}

.ChatList .row {
  padding: 3%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: row;
}

.ChatList .row .detail {
  padding-left: 3%;
  width: 90%;
}

.ChatList .row .time {
  margin-left: auto;
}

.ChatList .app-doc-name {
  word-wrap: break-all;
}

.ChatList .row.selected,
.ChatList .row:hover {
  background-color: var(--hover-bg);
}

.ChatList {
  overflow-y: auto;
}

.ChatList .row .badge {
  background: var(--primary-err-color);
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin-left: -8px;
  z-index: 1;
}

.chatListError {
  height: 93vh;
  text-align: center;
}

.chatListError .dimmable {
  top: 40%;
}

.ChatListError .button,
.ChatListError .button:hover,
.ChatListError .button:active,
.ChatListError .button:focus {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.ChatBox .messageView {
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.ChatBox .ChatBoxChoice,
.ChatBox .ChatBoxChoice .button {
  background-color: var(--secondary-bg-color);
  color: var(--primary-theme-color);
  height: 40px;
}

.ChatBox .ChatBoxChoice .fields {
  margin: 0;
}

.ChatBox .textBox {
  height: 110px;
  border-top: 1px solid var(--secondary-bg-color);
}

.ChatBox .textBox .button {
  vertical-align: middle;
  background-color: var(--primary-theme-color);
  color: black;
}

.ChatBox .textBox textarea {
  height: 100%;
  width: 100%;
}

.ChatBox .textBox textarea.readOnly {
  height: 100%;
  width: 100%;
  background-color: var(--secondary-bg-color);
}

.ChatBox .textBox textarea.readOnly::placeholder {
  color: var(--disable-bg-color);
}

.ChatBox .textBox textarea {
  resize: none;
  border: 0;
  padding: 1%;
}

.ChatBox .textBox textarea:focus {
  outline: 0;
}

.ChatBox .textBox .sendButton {
  display: none !important;
}

.ui.celled.grid.ChatTable.noMargin {
  margin: 0;
}

.ChatTable .chatBox {
  height: calc(100vh - 40px);
}

.mod-add-expense i {
  cursor: pointer;
}

.mod-add-expense .ui.segment {
  margin: 0;
}

.mod-add-expense > .ui.segment.mod-header {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border-radius: 0;
}

.mod-add-expense > .ui.segment.mod-header .mod-header-content {
  display: flex;
  justify-content: space-between;
}

.mod-add-expense > .ui.segment.mod-header .mod-header-content .ui.header {
  margin: 0;
  color: var(--primary-font-color);
}

.mod-add-expense .btn-group {
  text-align: center;
}

.mod-add-expense .btn-group .save-btn {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.mod-add-expense .btn-group .delete-btn {
  background-color: var(--primary-err-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.FriendMessage {
  margin: 1%;
}

.FriendMessage .field.friendName {
  margin: 0;
}

.FriendMessage .inline.fields .field.content {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1.3em;
  background-color: var(--chat-bubble-color);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 60%;
  border: var(--std-border);
}

.FriendMessage .inline.fields .field.sendDateTime {
  font-size: 12;
  padding: 0.5em;
  align-self: flex-end;
}

.FriendMessage .inline.fields .field.content .file {
  text-align: center;
  padding: 5px;
}

.FriendMessage .inline.fields .field.content .file .text {
  padding-top: 10px;
}

.MyMessage {
  margin: 1%;
}

.MyMessage .inline.fields .field.content {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--chat-bubble-color);
  border-radius: 1.3em;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 60%;
  border: var(--std-border);
}

.MyMessage .inline.fields .field.content.selected {
  background-color: var(--chat-bubble-color-selected);
  border: var(--std-border);
}

.MyMessage .inline.fields .field.content .file {
  text-align: center;
  padding: 5px;
}

.MyMessage .inline.fields .field.content .file .text {
  padding-top: 10px;
}

.MyMessage .inline.fields .field.sendDateTime {
  font-size: 12;
  padding: 0.5em;
  text-align: initial;
  align-self: flex-end;
}

.MyMessage .fields {
  direction: rtl;
}

.MyMessage .inline.fields .field.content.selected {
  background-color: rgba(78, 197, 165, 0.856);
}

.MyMessage .inline.fields .field.content .call.icon,
.FriendMessage .inline.fields .field.content .call.icon {
  transform: rotate(90deg);
}
